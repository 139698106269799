<template>
  <div>   
      <table class="table table-sm">
          <thead>
          <tr>
              <th> Length </th>
              <th> Current Price</th>
              <th>
              <button class="btn btn-sm btn-success py-0 float-right" @click="newLesson()">
                <font-awesome-icon icon="plus"/> New
              </button>
              </th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="lesson in lesson_lengths" :key="lesson.id">
                <td>{{lesson.duration}} min</td>
                <td>${{lesson.current_price / 100}}</td>
                <td>
                  <button v-bind:disabled="lesson.is_used" v-bind:class="lesson.is_used ? 'btn-secondary' : 'btn-danger'" class="btn btn-sm py-0 float-right" @click="deleteLesson(lesson.id)">
                    <font-awesome-icon icon="times-circle"/> Delete
                  </button>
                  <button class="btn btn-sm btn-primary py-0 mx-1 float-right" @click="editLesson(lesson.id)">
                    <font-awesome-icon icon="cog"/> Edit
                  </button>
                </td>
            </tr>
          </tbody>
      </table>
      
      <b-modal ref="lessonModal" id="lesson-modal" v-bind:title="lessonForm.edit_mode ? 'Edit Lesson Prices' : 'New Lesson Price'" hide-footer>
        <b-form @submit="submitLesson" @reset="resetLesson" class="w-100">

          <b-input-group id="form-duration-group" prepend="Duration" label-for="form-duration" class="my-2">
            <b-form-input type="number" id="form-duration" v-model="lessonForm.duration" v-bind:disabled="lessonForm.edit_mode">
            </b-form-input>
            <span class="input-group-text" style="border-top-left-radius: 0px; border-bottom-left-radius: 0px; border-left-width: 0px;">
              minutes
            </span>
          </b-input-group>

          <b-input-group id="form-new-price-group" class="my-1" style="padding-left: 75.5%; width: 100%">
            <button class="btn btn-success " @click="addPrice()" type="button" id="new-price">
              Add Price
              <font-awesome-icon icon="plus"/>
            </button>
          </b-input-group>

          <div v-for="(price,idx) in lessonForm.prices" :key="price.id" class="my-1">
            <b-input-group id="form-price-group" prepend="Price $" label-for="form-price">
              <b-form-input type="number" step="0.01" :id="'form-price-' + price.id" v-model="lessonForm.prices[idx].price" style="max-width: 20%" required>
              </b-form-input>

              <span class="input-group-text" style="border-radius: 0px; border-left-width: 0px; border-right-width: 0px;">
                Until: 
              </span>
              <b-form-input type="date" :id="'form-apply-' + price.id" v-model="lessonForm.prices[idx].apply_until" >
              </b-form-input>

              <b-button variant="danger" @click="deletePrice(idx, price.id);" style="border-top-left-radius: 0px; border-bottom-left-radius: 0px; border-left-width: 0px;">
                <font-awesome-icon icon="times-circle"/>
              </b-button>
            </b-input-group>
          </div>
          
          
          <hr>
          <b-button type="submit" v-bind:variant="lessonForm.edit_mode ? 'primary' : 'success'">
            {{lessonForm.edit_mode ? 'Save' : 'Create'}}
          </b-button>
          <b-button type="reset" variant="danger" class="mx-1">
            Cancel
          </b-button>
        </b-form>
      </b-modal>
      
  </div>
</template>

<script>
import { ApiFactory } from "../api/ApiFactory";

const LessonLengthAPI = ApiFactory.get("lesson_length");
const LessonPriceAPI = ApiFactory.get("lesson_price");

export default {
  data() {
    return {
      lesson_lengths: [],
      lessonForm: {
        duration: null,
        id: null,
        prices: [],
      }
    }
  },
  methods: {
    async getLessons(){
      const {data} = await LessonLengthAPI.get();
      this.lesson_lengths = data;
    },

    newLesson(){
      this.initLessonForm();
      this.$refs.lessonModal.show();
    },

    async deleteLesson(id){
      await LessonLengthAPI.deleteLessonLength(id);
      this.getLessons();
    },

    resetLesson(){
      this.$refs.lessonModal.hide();
      this.initLessonForm();
    },

    async submitLesson(evt){
      evt.preventDefault();
      this.$refs.lessonModal.hide();

      const payload = {
        duration: this.lessonForm.duration,
        prices: this.lessonForm.prices,
      };

      for (var i=0; i < payload.prices.length; i++){
        payload.prices[i].price *= 100;
        if (payload.prices[i].apply_until == ""){
          payload.prices[i].apply_until =  null;
        }
      }

      if (this.lessonForm.edit_mode){
        payload.id = this.lessonForm.id;
        await LessonLengthAPI.updateLessonLength(payload);
        this.getLessons();
      }else{
        const {data} = await LessonLengthAPI.createLessonLength(payload);
        this.lesson_lengths.push(data);
      }
    },

    async editLesson(id){
      const {data} = await LessonLengthAPI.getLessonLength(id);

      this.initLessonForm();
      this.lessonForm.edit_mode = true;
      this.lessonForm.duration = data.duration;
      this.lessonForm.id = id;
      for (var i=0; i < data.prices.length; i++){
        data.prices[i].price /= 100;
      }
      this.lessonForm.prices = data.prices;
      this.$refs.lessonModal.show();
    },

    initLessonForm(){
      this.lessonForm.edit_mode = false;
      this.lessonForm.duration = null;
      this.lessonForm.prices = [];
    },

    deletePrice(idx, price_id){
      if (price_id){
        LessonPriceAPI.deleteLessonPrice(price_id);
      }
      this.lessonForm.prices.splice(idx, 1)
      
    },

    addPrice(){
      this.lessonForm.prices.push({
        apply_until:null,
        length: this.lessonForm.id,
        price:null,
      });
    }
  },

  created: function(){
    this.getLessons();
  },
}
</script>