<template>
  <div class="container my-3">
    <div class="row py-2 ">
      <div class="col-sm-6">
        <b-card title="Recitals">
          <RecitalsTable>
          </RecitalsTable>
        </b-card>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <b-card title="Lesson Lengths">
          <LessonsTable>
          </LessonsTable>
        </b-card>
      </div>
      <div class="col-sm-6">
        <b-card title="Discounts">
          <DiscountsTable>
          </DiscountsTable>
        </b-card>
      </div>
    </div>
    
     
  </div>
</template>

<script>
import LessonsTable from "../components/LessonsTable";
import DiscountsTable from "../components/DiscountsTable";
import RecitalsTable from "../components/RecitalsTable";

export default {
  props: {
    year: {
      type: Number,
      default: null
    }
  },
  components: {
    LessonsTable,
    DiscountsTable,
    RecitalsTable
  }
}
</script>