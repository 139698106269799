<template>
  <div>   
      <table class="table table-sm">
          <thead>
          <tr>
              <th> Name </th>
              <th> Apply to</th>
              <th>
                <button class="btn btn-sm btn-success py-0 float-right" @click="newDiscount()">
                    <font-awesome-icon icon="plus"/> New
                </button>
              </th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="discount in discounts" :key="discount.id">
                <td>{{discount.name}}</td>
                <td>{{discount.apply_at}}{{NTH[discount.apply_at]}} student</td>
                <td>
                  <button v-bind:disabled="discount.is_used" v-bind:class="discount.is_used ? 'btn-secondary' : 'btn-danger'" class="btn btn-sm py-0 float-right" @click="deleteDiscount(discount.id)">
                    <font-awesome-icon icon="times-circle"/> Delete
                  </button>
                  <button class="btn btn-sm btn-primary py-0 mx-1 float-right" @click="editDiscount(discount.id)" >
                    <font-awesome-icon icon="cog"/> Edit
                  </button>
                </td>
            </tr>
          </tbody>
      </table>

      <b-modal ref="discountModal" id="discount-modal" v-bind:title="discountForm.edit_mode ? 'Edit Discount' : 'New Discount'" hide-footer>
        <b-form @submit="submitDiscount" @reset="resetDiscount" class="w-100">

          <b-input-group id="form-name-group" prepend="Name" label-for="form-discount-name" class="my-2">
            <b-form-input id="form-discount-name" v-model="discountForm.name" required>
            </b-form-input>
          </b-input-group>

          <b-input-group id="form-expression-group" prepend="Expression" label-for="form-expression" class="my-2">
            <b-form-input id="form-expression" v-model="discountForm.expression" required>
            </b-form-input>
          </b-input-group>

          <b-input-group id="form-apply-at-group" prepend="Apply At" label-for="form-apply-at" class="my-2">
            <b-form-input type="number" id="form-apply-at" v-model="discountForm.apply_at" >
            </b-form-input>
            <span class="input-group-text" style="border-radius: 0px;">
              {{NTH[discountForm.apply_at]}} student
            </span>
          </b-input-group>
          
          <hr>
          <b-button type="submit" v-bind:variant="discountForm.edit_mode ? 'primary' : 'success'">
            {{discountForm.edit_mode ? 'Save' : 'Create'}}
          </b-button>
          <b-button type="reset" variant="danger" class="mx-1">
            Cancel
          </b-button>
        </b-form>
      </b-modal>
  </div>
</template>

<script>
import { ApiFactory } from "../api/ApiFactory";
const DiscountAPI = ApiFactory.get("discount");
const NTH = {null: 'th', 0: 'th', 1:'st', 2:'nd', 3:'rd', 4:'th', 5:'th', 6:'th', 7:'th', 8:'th', 9:'th'}

export default {
  data() {
    return {
      discounts: [],
      NTH: NTH,
      discountForm: {
        edit_mode: false,
        apply_at: null,
        expression: null,
        id: null,
        name: null
      },
    }
  },
  methods: {
    async getDiscounts(){
      const {data} = await DiscountAPI.get();
      this.discounts = data;
    },

    async editDiscount(id){
      const {data} = await DiscountAPI.getDiscount(id);

      this.initDiscountForm();
      this.discountForm.edit_mode = true;
      this.discountForm.apply_at = data.apply_at;
      this.discountForm.name = data.name;
      this.discountForm.expression = data.expression;
      this.discountForm.id = id;
      this.$refs.discountModal.show();
    },

    async submitDiscount(evt){
      evt.preventDefault()
      this.$refs.discountModal.hide();

      const payload = {
        apply_at: this.discountForm.apply_at,
        name: this.discountForm.name,
        expression: this.discountForm.expression
      };
      if (this.discountForm.edit_mode){
        payload.id = this.discountForm.id;
        await DiscountAPI.updateDiscount(payload);
        this.getDiscounts();
      }else{
        const {data} = await DiscountAPI.createDiscount(payload);
        this.discounts.push(data);
      }
    },

    resetDiscount(){
      this.initDiscountForm();
      this.$refs.discountModal.hide();
    },

    async deleteDiscount(id){
      await DiscountAPI.deleteDiscount(id);
      this.getDiscounts();
    },
    
    newDiscount(){
      this.initDiscountForm();
      this.$refs.discountModal.show();
    },

    initDiscountForm(){
      this.discountForm.id = null;
      this.discountForm.edit_mode = false;
      this.discountForm.apply_at = null;
      this.discountForm.expression = null;
      this.discountForm.id = null;
      this.discountForm.name = null
    },
  
  },

  created: function(){
    this.getDiscounts();
  },
}
</script>