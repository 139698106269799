<template>
  <div>   
      <table class="table table-sm">
          <thead>
          <tr>
              <th> Name </th>
              <th> Date </th>
              <th>
                <button class="btn btn-sm btn-success py-0 float-right" @click="newRecital()">
                    <font-awesome-icon icon="plus"/> New
                </button>
              </th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="recital in recitals" :key="recital.id">
                <td>{{recital.name}}</td>
                <td>{{recital.week_of | moment("MM/DD/YYYY")}}</td>
                <td>
                  <button class="btn btn-sm py-0 float-right btn-danger" @click="deleteRecital(recital.id)">
                    <font-awesome-icon icon="times-circle"/> Delete
                  </button>
                  <button class="btn btn-sm btn-primary py-0 mx-1 float-right" @click="editRecital(recital.id)" >
                    <font-awesome-icon icon="cog"/> Edit
                  </button>
                </td>
            </tr>
          </tbody>
      </table>

      <b-modal ref="recitalModal" id="recital-modal" v-bind:title="recitalForm.edit_mode ? 'Edit Recital' : 'New Recital'" hide-footer>
        <b-form @submit="submitRecital" @reset="resetRecital" class="w-100">

          <b-input-group id="form-name-group" prepend="Name" label-for="form-recital-name" class="my-2">
            <b-form-input id="form-recital-name" v-model="recitalForm.name" required>
            </b-form-input>
          </b-input-group>

          <b-input-group id="form-date-group" prepend="Date" label-for="form-date" class="my-2">
            <b-form-input id="form-date" v-model="recitalForm.week_of" required type="date">
            </b-form-input>
          </b-input-group>
          
          <hr>
          <b-button type="submit" v-bind:variant="recitalForm.edit_mode ? 'primary' : 'success'">
            {{recitalForm.edit_mode ? 'Save' : 'Create'}}
          </b-button>
          <b-button type="reset" variant="danger" class="mx-1">
            Cancel
          </b-button>
        </b-form>
      </b-modal>
  </div>
</template>

<script>
import { ApiFactory } from "../api/ApiFactory";
const RecitalAPI = ApiFactory.get("recitals");

export default {
  data() {
    return {
      recitals: [],
      recitalForm: {
        edit_mode: false,
        week_of: null,
        id: null,
        name: null
      },
    }
  },
  methods: {
    async getRecitals(){
      const {data} = await RecitalAPI.get();
      this.recitals = data;
    },

    async editRecital(id){
      const {data} = await RecitalAPI.getRecital(id);

      this.initRecitalForm();
      this.recitalForm.edit_mode = true;
      this.recitalForm.name = data.name;
      this.recitalForm.week_of = data.week_of;
      this.recitalForm.id = id;
      this.$refs.recitalModal.show();
    },

    async submitRecital(evt){
      evt.preventDefault()
      this.$refs.recitalModal.hide();

      const payload = {
        week_of: this.recitalForm.week_of,
        name: this.recitalForm.name,
      };
      if (this.recitalForm.edit_mode){
        payload.id = this.recitalForm.id;
        await RecitalAPI.updateRecital(payload);
        this.getRecitals();
      }else{
        const {data} = await RecitalAPI.createRecital(payload);
        this.recitals.push(data);
      }
    },

    resetRecital(){
      this.initRecitalForm();
      this.$refs.recitalModal.hide();
    },

    async deleteRecital(id){
      await RecitalAPI.deleteRecital(id);
      this.getRecitals();
    },
    
    newRecital(){
      this.initRecitalForm();
      this.$refs.recitalModal.show();
    },

    initRecitalForm(){
      this.recitalForm.edit_mode = false;
      this.recitalForm.week_of = null;
      this.recitalForm.id = null;
      this.recitalForm.name = null
    },
  
  },

  created: function(){
    this.getRecitals();
  },
}
</script>